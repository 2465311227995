var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"panel-address"},[_c('b-row',[(_vm.type !== 'shipping')?_c('b-col',{attrs:{"md":"4"}},[_c('InputSelect',{ref:"nameTitleId",staticClass:"f-regular",attrs:{"title":"คำนำหน้าชื่อ","text":"คำนำหน้าชื่อ","name":"nameTitleId","valueField":"id","textField":"name","size":"lg","options":_vm.nameTitleArray,"isRequired":"","isValidate":_vm.v.nameTitleId.$error,"v":_vm.v.nameTitleId},on:{"onDataChange":function (val) { return (_vm.form.nameTitleId = val); }},model:{value:(_vm.form.nameTitleId),callback:function ($$v) {_vm.$set(_vm.form, "nameTitleId", $$v)},expression:"form.nameTitleId"}})],1):_vm._e(),(_vm.type !== 'shipping')?_c('b-col',{attrs:{"md":"4"}},[_c('InputText',{staticClass:"f-regular",attrs:{"textFloat":"ชื่อ","placeholder":"ชื่อ","type":"text","name":"nameOrCompanyName","size":"lg","isRequired":"","isValidate":_vm.v.firstname.$error,"v":_vm.v.firstname},on:{"onkeyup":function($event){return _vm.handleAddress(
              _vm.data.buyerInfo.sameAddress,
              _vm.data.buyerInfo.shippingAddress
            )}},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})],1):_vm._e(),(_vm.type !== 'shipping')?_c('b-col',{attrs:{"md":"4"}},[_c('InputText',{staticClass:"f-regular",attrs:{"textFloat":"นามสกุล","placeholder":"นามสกุล","type":"text","name":"lastname","size":"lg","isRequired":"","isValidate":_vm.v.lastname.$error,"v":_vm.v.lastname},on:{"onkeyup":function($event){return _vm.handleAddress(
              _vm.data.buyerInfo.sameAddress,
              _vm.data.buyerInfo.shippingAddress
            )}},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}})],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('InputText',{staticClass:"f-regular",attrs:{"textFloat":"ที่อยู่","placeholder":"ที่อยู่","type":"text","name":"address","size":"lg","isRequired":"","isValidate":_vm.v.address.$error,"v":_vm.v.address},on:{"onkeyup":function($event){return _vm.handleAddress(
              _vm.data.buyerInfo.sameAddress,
              _vm.data.buyerInfo.shippingAddress
            )}},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('InputText',{staticClass:"f-regular",attrs:{"textFloat":"ถนน","placeholder":"ถนน","type":"text","name":"road","size":"lg","v":_vm.v.road},on:{"onkeyup":function($event){return _vm.handleAddress(
              _vm.data.buyerInfo.sameAddress,
              _vm.data.buyerInfo.shippingAddress
            )}},model:{value:(_vm.form.road),callback:function ($$v) {_vm.$set(_vm.form, "road", $$v)},expression:"form.road"}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('InputZipcode',{ref:"zipCode",staticClass:"f-regular",attrs:{"itemsResults":_vm.resultsAddress,"value":_vm.form.zipcode,"textFloat":"รหัสไปรษณีย์","placeholder":"รหัสไปรษณีย์","type":"tel","name":"zipCode","isRequired":"","v":_vm.v.zipcode,"isValidate":_vm.v.zipcode.$error,"maxlength":5,"getAddress":_vm.getAddress,"setAddress":_vm.setAddress,"clearFields":_vm.clearFields,"isDisplay":_vm.isDisplay,"size":"lg"},on:{"onkeypress":_vm.isNumber,"onkeyup":function($event){return _vm.handleAddress(
              _vm.data.buyerInfo.sameAddress,
              _vm.data.buyerInfo.shippingAddress
            )}},model:{value:(_vm.form.zipcode),callback:function ($$v) {_vm.$set(_vm.form, "zipcode", $$v)},expression:"form.zipcode"}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('InputText',{staticClass:"f-regular",attrs:{"textFloat":"จังหวัด","placeholder":"จังหวัด","type":"text","name":"province","isDisplay":true,"size":"lg","v":_vm.v.province,"isRequired":"","isValidate":_vm.v.province.$error},on:{"onkeyup":function($event){return _vm.handleAddress(
              _vm.data.buyerInfo.sameAddress,
              _vm.data.buyerInfo.shippingAddress
            )}},model:{value:(_vm.form.province),callback:function ($$v) {_vm.$set(_vm.form, "province", $$v)},expression:"form.province"}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('InputText',{staticClass:"f-regular",attrs:{"textFloat":"อำเภอ","placeholder":"อำเภอ","type":"text","name":"district","isDisplay":true,"size":"lg","v":_vm.v.district,"isRequired":"","isValidate":_vm.v.district.$error},on:{"onkeyup":function($event){return _vm.handleAddress(
              _vm.data.buyerInfo.sameAddress,
              _vm.data.buyerInfo.shippingAddress
            )}},model:{value:(_vm.form.district),callback:function ($$v) {_vm.$set(_vm.form, "district", $$v)},expression:"form.district"}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('InputText',{staticClass:"f-regular",attrs:{"textFloat":"ตำบล/แขวง","placeholder":"ตำบล/แขวง","type":"text","name":"subdistrict","size":"lg","v":_vm.v.subdistrict,"isRequired":"","isValidate":_vm.v.subdistrict.$error},on:{"onkeyup":function($event){return _vm.handleAddress(
              _vm.data.buyerInfo.sameAddress,
              _vm.data.buyerInfo.shippingAddress
            )}},model:{value:(_vm.form.subdistrict),callback:function ($$v) {_vm.$set(_vm.form, "subdistrict", $$v)},expression:"form.subdistrict"}})],1),(_vm.type == 'billing')?_c('b-col',{attrs:{"md":"6"}},[_c('InputText',{attrs:{"textFloat":"เลขประจำตัวผู้เสียภาษี","placeholder":"เลขประจำตัวผู้เสียภาษี","type":"text","name":"taxID","size":"lg","isRequired":"","isValidate":_vm.v.tax.$error,"v":_vm.v.tax,"maxlength":13},on:{"onkeypress":_vm.isNumber},model:{value:(_vm.form.tax),callback:function ($$v) {_vm.$set(_vm.form, "tax", $$v)},expression:"form.tax"}})],1):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }